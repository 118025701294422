<template>
  <div class="min-h-screen">
    <pass-header :integration-type="integrationType">
      <div class="flex items-center w-full">
        <button
          v-if="!isFeatureToggled(appFeatures.FEATURE_DEMO_SCRIPT)"
          class="mr-5 focus:outline-none"
          @click="toggleBackToScriptsPage"
        >
          <icon-base :icon-name="$t('pass.backToList')">
            <icon-arrow-left />
          </icon-base>
        </button>
        <div class="w-full flex items-center justify-between">
          <skeleton-header v-if="titleName === null" />
          <div v-else class="flex gap-5 items-center transition-all">
            <h1 ref="contextButtonsTrigger" class="pass-view__title line-clamp-2 lg:line-clamp-1" @click="widenTitle">
              {{ titleName }}
            </h1>
            <button
              class="hidden lg:block focus:outline-none text-hs-info relative w-5 h-5"
              @click="toggleIsStarredScript"
            >
              <transition name="fade" mode="out-in">
                <div
                  :key="isStarredScript ? 'starred' : 'not-starred'"
                  class="absolute top-0 flex items-center transition-all"
                >
                  <el-tooltip
                    popper-class="add-remove-starred-popper-tooltip"
                    effect="light"
                    :content="$t(isStarredScript ? 'pass.removeFromStarred' : 'pass.addToStarred')"
                    placement="right"
                  >
                    <icon-base class="outline-none mb-[3px]" width="20" height="20" view-box="0 0 48 48" hide-title>
                      <component :is="isStarredScript ? 'icon-star' : 'icon-star-outlined'" />
                    </icon-base>
                  </el-tooltip>
                </div>
              </transition>
            </button>
          </div>
          <div
            ref="contextButtonsWrapper"
            tabindex="0"
            :style="{
              width: openedContextButtons
                ? isFeatureToggled(appFeatures.FEATURE_DEMO_SCRIPT)
                  ? '30px'
                  : '80px'
                : '1px',
              transform: openedContextButtons ? 'translateX(0)' : 'translateX(100px)',
            }"
            class="lg:hidden flex outline-none gap-2 items-center transition-all"
          >
            <pass-script-context-menu-button
              v-if="!isFeatureToggled(appFeatures.FEATURE_DEMO_SCRIPT)"
              @click="openedScriptMenu = true"
            />
            <button
              class="lg:hidden focus:outline-none flex w-9 h-9 items-center justify-center bg-hs-light-gray rounded-full"
              @click="$store.dispatch('app/TOGGLE_TOOLBAR')"
            >
              <icon-base :icon-name="$t('menu.open')">
                <icon-sidebar />
              </icon-base>
            </button>
          </div>
        </div>
      </div>
    </pass-header>
    <!-- body -->
    <div class="relative w-full min-h-full flex">
      <!-- view -->
      <div class="flex-1 w-full max-w-full min-h-screen">
        <div
          v-show="!isEmptyNoAnswer"
          ref="pass-view-container"
          class="pass-view-container relative max-h-screen overflow-x-hidden overflow-y-auto lg:pr-24"
          :class="{
            'pass-view-container--demo': isFeatureToggled(appFeatures.FEATURE_DEMO_SCRIPT),
          }"
        >
          <div ref="scriptReaderBodyTopHook" class="pass-space-for-header" />
          <!-- history -->
          <div class="pass-history-body opacity-30">
            <pass-history />
          </div>
          <!-- /history -->
          <div ref="scriptReaderBodyBottomHook" class="script-reader-body-bottom-hook" />
          <!-- current-step -->
          <div class="pass-current-step-body pb-20 lg:pb-5 pl-5 lg:pl-8 pr-5 pt-2">
            <skeleton-current-step-body v-if="!isStarted || isWaitingCurrentScript" />
            <pass-current-step-body v-else />
          </div>
          <!-- current-step -->
          <div class="pass-space-for-footer" />
        </div>
        <is-empty-no-answer v-if="currentState && currentStepData && isEmptyNoAnswer" />
      </div>
      <!-- /view -->
      <!-- toolbar -->
      <pass-toolbar
        v-if="!isEmptyNoAnswer"
        :current-step-data="currentStepData"
        :is-waiting="!isStarted || isWaitingCurrentScript"
        :current-quick-transitions="currentQuickTransitions"
        :current-view-notes="currentViewNotes"
        :current-script="currentScript"
        :isOpen="isOpen"
        :i18n="$i18n"
        :on-field-change="fieldChangeHandler"
        :set-value-fn="setValueFn"
        @toggle-toolbar="toggleBtnClose"
        @toggle-quick-transition="send($event, null, true)"
        @toolbar-mounted="fixCurrentTab"
      />
      <!-- /toolbar -->
    </div>
    <!-- /body -->
    <pass-footer v-if="!isEmptyNoAnswer" :integration-type="integrationType">
      <skeleton-hs-main-button v-if="!isStarted" class="lg:mr-5" />
      <hs-main-button
        v-if="isStarted && !isLastVariant"
        variant="primary"
        custom-class="fixed bottom-0 left-0 lg:relative lg:bottom-auto lg:left-auto py-2.5 w-full lg:w-72 block lg:inline-block font-bold text-base text-white bg-hs-primary border-2 border-hs-primary rounded-none lg:rounded-xl lg:mr-5 "
        @click="() => done()"
      >
        {{ $t('pass.conversationCompleted') }}
      </hs-main-button>
      <p v-if="scriptWaitingToUpdate && !isWaitingPassCallback" class="hidden lg:inline text-sm flex-1">
        <i class="el-icon-info text-hs-secondary mr-1" />
        {{ $t('pass.futureScriptStatus.updated') }}.
      </p>
      <p v-if="scriptWaitingToRemove && !isWaitingPassCallback" class="hidden lg:inline text-sm flex-1">
        <i class="el-icon-info text-hs-secondary mr-1" />
        {{ $t('pass.futureScriptStatus.deleted') }}.
      </p>
    </pass-footer>
    <pass-fade-message :value="isSuccessSave">
      {{ $t('pass.saved') }}
    </pass-fade-message>
    <pass-fade-message :value="isTextCopiedToClipboard">
      {{ $t('pass.textCopied') }}
    </pass-fade-message>
    <mobile-step-context-menu :header="$t('pass.stepMenu.title')" />
    <mobile-script-context-menu
      :is-starred-script="isStarredScript"
      :opened-script-menu="openedScriptMenu"
      :header="$t('pass.scriptMenu.title')"
      @closeMenu="openedScriptMenu = false"
      @toggleStarred="toggleIsStarredScript"
    />

    <pass-fade-message :value="isAddedToStarred">
      <p class="w-full px-1 text-white text-center">
        {{ $t('pass.addedToStarred') }}
      </p>
    </pass-fade-message>
    <pass-fade-message :value="isRemovedFromStarred">
      <p class="w-full px-1 text-white text-center">
        {{ $t('pass.removedFromStarred') }}
      </p>
    </pass-fade-message>
    <pass-fade-message :value="isSomethingWentWrong">
      <p class="w-full px-1 text-white text-center">
        {{ $t('pass.somethingWentWrong') }}
      </p>
    </pass-fade-message>
  </div>
</template>

<script>
import { nextTick } from 'vue'
/* eslint-disable */
// ui
import PassHeader from '@/core/pass/components/headers/pass-header.vue'
import IconArrowLeft from '@/core/design-system/icon-base/icons/arrow-left.vue'
import IconStar from '@/core/design-system/icon-base/icons/star.vue'
import IconStarOutlined from '@/core/design-system/icon-base/icons/star-outlined.vue'
import IconSidebar from '@/core/design-system/icon-base/icons/sidebar.vue'
import HsMainButton from '@/core/design-system/buttons/hs-main-button.vue'
import SkeletonHeader from '@/core/design-system/skeletons/skeleton-header.vue'
import SkeletonHsMainButton from '@/core/design-system/skeletons/skeleton-hs-main-button.vue'
import SkeletonCurrentStepBody from '@/core/pass/components/skeletons/skeleton-current-step-body.vue'
import PassFadeMessage from '@/core/pass/components/pass-elements/pass-notifications/pass-fade-message.vue'
import MobileStepContextMenu from '@/core/pass/components/pass-elements/pass-step-context-menu/mobile-step-context-menu.vue'
import MobileScriptContextMenu from '../components/pass-elements/pass-script-context-menu/mobile-script-context-menu.vue'
import PassScriptContextMenuButton from '../components/pass-elements/pass-script-context-menu/pass-script-context-menu-button.vue'

// pass-elements
import PassCurrentStepBody from '@/core/pass/components/pass-elements/pass-body/pass-current-step-body.vue'
// import PassToolbar from '@/core/pass/components/pass-elements/pass-toolbar/pass-toolbar.vue'
import { PassToolbar } from '@smpartners/hyperscript-pass-ui'
import PassFooter from '@/core/pass/components/pass-elements/pass-footer.vue'
import PassHistory from '@/core/pass/components/pass-elements/pass-body/pass-history.vue'
import IsEmptyNoAnswer from '@/core/pass/components/pass-elements/pass-body/is-empty-no-answer.vue'

import { onScriptStart, onScriptFinish, onStepPassed, messagingBus } from '@/core/infrastructure/messaging/messaging'
import { eventBus } from '../../infrastructure/event-bus/event-bus'

import { onFieldChange } from '@/core/infrastructure/messaging/messaging'

export default {
  components: {
    PassHeader,
    IconArrowLeft,
    IconStar,
    IconStarOutlined,
    IconSidebar,
    HsMainButton,
    PassCurrentStepBody,
    PassToolbar,
    PassFooter,
    SkeletonHeader,
    PassHistory,
    SkeletonHsMainButton,
    SkeletonCurrentStepBody,
    IsEmptyNoAnswer,
    PassFadeMessage,
    MobileStepContextMenu,
    MobileScriptContextMenu,
    PassScriptContextMenuButton,
  },
  props: {
    integrationType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    // current script operating data
    currentMachine: null,
    currentState: null,
    // flags
    isEmptyNoAnswer: false,
    doneWithRedirect: false,
    requestInSend: false,
    isLocalLoading: false,
    isSuccessSave: false,
    isTextCopiedToClipboard: false,
    openedScriptMenu: false,
    isAddedToStarred: false,
    isRemovedFromStarred: false,
    isSomethingWentWrong: false,
    openedContextButtons: true,
  }),
  computed: {
    isWaiting() {
      return this.$store.getters.app.isWaiting
    },
    isWaitingCurrentScript() {
      return this.$store.getters.app.isWaitingCurrentScript
    },
    isWaitingPassCallback() {
      return this.$store.getters.app.isWaitingPassCallback
    },
    isTextCopiedToClipboardCallback() {
      // isTextCopiedToClipboard
      return this.$store.getters.clipboard.isTextCopiedToClipboardCallback
    },
    isStarted() {
      return this.$store.getters.cache.startTime !== null
    },
    titleName() {
      return this.$store.getters.cache.titleName
    },
    currentScript() {
      return this.$store.getters.cache.currentScript
    },
    isOpen() {
      return this.$store.getters.app.toolbar.isOpen
    },
    currentStepData() {
      const isInitEvent = this.currentState?._event?.name === 'xstate.init'
      const targetState = isInitEvent ? this.currentState?.value : this.currentState?._event?.name
      const { steps, connections } = this.currentScript?.data || {}
      const step = steps?.find((step) => step.id === targetState)
      const variants = connections?.filter((connection) => connection.source === targetState)
      return { step, variants }
    },
    isLastVariant() {
      return this.currentScript?.data && this.currentStepData.variants?.length === 0
    },
    isNotLastVariant() {
      return this.currentScript?.data && this.currentStepData.variants?.length > 0
    },
    currentHistoryLog() {
      const goodFormatLog = (arrLog, _uid) => {
        const log = arrLog.map((e) => ({
          step: this.$store.getters.cache.currentWorkspace[_uid].data.steps.find((step) => step.id === e.id),
          variants: this.$store.getters.cache.currentWorkspace[_uid].data.connections.filter((f) => f.source === e.id),
          _viaButton: e.cid,
        }))
        return log
      }
      const arr = Object.entries(this.$store.getters.pass.passStack).map(([key, value]) => [
        key,
        goodFormatLog(value.log, key),
      ])
      return Object.fromEntries(arr)
    },
    currentQuickTransitions() {
      const starred = this.currentScript.data?.starred || []
      const qt = starred.map((e) => {
        const stepsWithLabels = e.s
          ? e.s.map((s) => {
              const findText = (arrOfObj) => {
                if (arrOfObj) {
                  // check if there is text
                  arrOfObj.forEach((e) => {
                    str += e.t
                    if (e._) {
                      findText(e._)
                    }
                  })
                }
              }
              const find = this.currentScript.data.steps.find((l) => l.id === s)
              let str = ''
              if (find && !find.title) {
                findText(find.text)
                str = str.replace(/undefined/g, '')
                if (str.length > 35) {
                  str = str.substr(0, 35) + '...'
                }
              }
              const getStr = str.length > 0 ? str : this.$t('pass.quickTransitions.newStep')
              const label = find ? find.title || getStr : null
              return {
                target: s,
                label,
                is_starred: find ? find.is_starred : false,
              }
            })
          : []
        return {
          n: e.n,
          s: stepsWithLabels.filter((e) => e.label !== null && e.is_starred),
        }
      })
      return qt
    },
    currentViewNotes() {
      return this.currentScript.view_notes || {}
    },
    currentScriptOptions() {
      return this.currentScript.options || {}
    },
    scriptWaitingToUpdate() {
      return this.$store.getters.cache.trigger === 'update'
    },
    scriptWaitingToRemove() {
      return this.$store.getters.cache.trigger === 'remove'
    },
    isStarredScript() {
      return this.$store.getters.starredScripts.starredScripts.includes(this.currentScript.id)
    },
  },
  watch: {
    isStarted(val) {
      if (val === true) {
        this.currentMachine = this.currentScript._xMachine
        // start
        this.currentMachine
          .onTransition((state) => {
            this.currentState = state
          })
          .start()

        this.initPass()
      }
    },
    isWaitingPassCallback(val) {
      if (val === false) {
        this.isSuccessSave = true
        setTimeout(() => {
          this.isSuccessSave = false
        }, 1000)
      }
    },
    isTextCopiedToClipboardCallback(val) {
      if (val === false) {
        this.isTextCopiedToClipboard = true
        setTimeout(() => {
          this.isTextCopiedToClipboard = false
        }, 1000)
      }
    },
    currentScript: {
      handler() {
        this.$store.dispatch('starredScripts/GET_STARRED_SCRIPTS')
      },
      deep: true,
    },
  },
  created() {
    messagingBus.on('setFieldValue', (data) => {
      if (this.currentScript.fields) {
        if (data.id) {
          const field = this.currentScript.fields[data.id]
          if (field) field.value = data.value
        }
        if (data.name) {
          const field = Object.values(this.currentScript.fields).find(
            (f) => f.sys_name === data.name || f.name === data.name,
          )
          if (field) field.value = data.value
        }
      }
    })

    messagingBus.on('setFieldOptions', (data) => {
      if (this.currentScript.fields) {
        let field
        if (data.id) {
          field = this.currentScript.fields[data.id]
        }
        if (data.name) {
          field = Object.values(this.currentScript.fields).find((f) => f.sys_name === data.name || f.name === data.name)
        }
        if (field) {
          if (!field.options) field.options = { list_values: [] }

          field.options.list_values = data.options
          // this.$set(field, 'options', {'list_values': data.options})
          field.options = { list_values: data.options }
        }
      }
    })

    messagingBus.on('setFieldAutocomplete', (data) => {
      if (this.currentScript.fields) {
        let field
        if (data.id) {
          field = this.currentScript.fields[data.id]
        }
        if (data.name) {
          field = Object.values(this.currentScript.fields).find((f) => f.sys_name === data.name || f.name === data.name)
        }
        if (field) {
          // this.$set(field, 'options', {'autocomplete': data.autocomplete})
          field.options = { autocomplete: data.autocomplete }
        }
      }
    })

    messagingBus.on('setFieldVisibility', (data) => {
      if (this.currentScript.fields) {
        let field
        if (data.id) {
          field = this.currentScript.fields[data.id]
        }
        if (data.name) {
          field = Object.values(this.currentScript.fields).find((f) => f.sys_name === data.name || f.name === data.name)
        }
        if (field)
          // this.$set(field, 'visibility', data.visibility)
          field.visibility = data.visibility
      }
    })
  },
  async mounted() {
    if (this.isWaiting === false || this.isFeatureToggled(this.appFeatures.FEATURE_DEMO_SCRIPT)) {
      await this.$store.dispatch('cache/GET_CURRENT_SCRIPT', {
        vm: this,
        id: this.$route.params.id,
        source: this.integrationType,
      })
    }
    window.addEventListener('beforeunload', this.sendBeacon)
  },
  beforeMount() {
    window.removeEventListener('beforeunload', this.sendBeacon)
  },
  methods: {
    setValueFn(id, value) {
      this.$store.dispatch('cache/CHANGE_FIELD', { id, value, _uid: this.currentScript._uid })
    },
    fieldChangeHandler(data) {
      onFieldChange(data)
    },
    toggleBtnClose(val) {
      if (this.customTitle) {
        this.setCurrentNote(null)
      } else {
        if (val !== undefined) {
          this.$store.dispatch('app/TOGGLE_TOOLBAR', val)
        } else {
          this.$store.dispatch('app/TOGGLE_TOOLBAR')
        }
      }
    },
    fixCurrentTab() {
      eventBus.on('fixCurrentTabActiveInPassToolbar', this.fixCurrentTabActiveInPassToolbar)
    },
    fixCurrentTabActiveInPassToolbar() {
      nextTick(() => {
        const tab = document.querySelector('.toolbar-tab')
        setTimeout(() => {
          tab.click()
        }, 500)
      })
    },
    sendBeacon() {
      if (this.isFeatureToggled(this.appFeatures.FEATURE_DEMO_SCRIPT)) return
      if (this.isStarted === false) {
        return
      }
      if (this.currentScriptOptions?.autoPassRegistration === false) {
        return
      }
      const pass = Object.values(this.$store.getters.pass.passStack)[0]
      if (pass.log.length > 1) {
        this.$store.dispatch('cache/PASS_SEND_BEACON')
      }
    },
    scrollToStart() {
      const el = this.$refs.scriptReaderBodyTopHook
      this.$nextTick(() => {
        el.scrollIntoView({ block: 'start', behavior: 'smooth' })
      })
    },

    scrollToEnd() {
      const el = this.$refs.scriptReaderBodyBottomHook
      this.$nextTick(() => {
        el.scrollIntoView({ block: 'start', behavior: 'smooth' })
      })
    },

    /**
     * send - transition to the next step of the script
     * @param {String} event
     * @param {String} buttonId
     * @param {Boolean} isQuickTransition - [flag whether the transition is fast]
     */
    async send(event, buttonId, isQuickTransition = false) {
      const ev = event.split('_')
      console.log('🐹 ev', ev)

      onStepPassed({
        stepId: this.currentStepData.step?.id,
        nextStepId: ev[0],
        isGoal: this.currentStepData.step?.is_goal,
        isUserSort: this.currentStepData.step?.is_user_sort,
        title: this.currentStepData.step?.title,
      })

      if (ev.length === 2) {
        this.isLocalLoading = true
        const subScriptId = parseInt(ev[0], 10)
        const stepTargetId = ev[1]

        await this.$store.dispatch('cache/GET_CURRENT_SCRIPT', {
          id: subScriptId,
          vm: this,
          isSubScript: true,
        })

        this.currentMachine = this.currentScript._xMachine
        // start
        this.currentMachine
          .onTransition((state) => {
            this.currentState = state
          })
          .start()

        this.$store.dispatch('pass/INIT_PASS')
        this.currentMachine.send(ev[1])
        this.$nextTick(() => {
          this.$store.dispatch('pass/LOG', {
            stepId: stepTargetId,
            buttonId: buttonId,
            valueUnexpected: null,
            isGoal: this.currentStepData.step?.is_goal,
            isUserSort: this.currentStepData.step?.is_user_sort,
          })
          this.scrollToEnd()
        })
        this.isLocalLoading = false
      } else {
        this.$nextTick(() => {
          this.currentMachine.send(event)
          this.$store.dispatch('pass/LOG', {
            stepId: this.currentStepData.step?.id,
            buttonId: isQuickTransition ? 'starred' : buttonId,
            valueUnexpected: null,
            isGoal: this.currentStepData.step?.is_goal,
            isUserSort: this.currentStepData.step?.is_user_sort,
          })
          this.scrollToEnd()
        })
      }
    },

    /**
     * backSend - move to the next step of the script
     * @param {String} event
     * @param {String} buttonId
     * @param {String} logIndex - [index of the step from the history of the passage]
     * @param {String} _scriptUid - [scripts mark in the history]
     */
    backSend(event, buttonId, logIndex, _scriptUid) {
      // console.log('🐹 backSend', event, buttonId, logIndex, _scriptUid)

      const ev = event.split('_')
      console.log('🐹 ev', ev)

      const currentScriptUid = this.currentScript._uid
      const stepData = {
        stepId: this.currentStepData.step?.id,
        nextStepId: ev[0],
        isGoal: this.currentStepData.step?.is_goal,
        isUserSort: this.currentStepData.step?.is_user_sort,
      }
      onStepPassed(stepData)
      if (ev.length === 2) {
        this.$store.dispatch('pass/BACK_TO_VIA_INDEX', {
          idx: logIndex,
          _uid: _scriptUid,
        })
        this.$store.dispatch('cache/CHANGE_CURRENT_SCRIPT', {
          _uid: _scriptUid,
        })

        this.$nextTick(() => {
          this.send(event, buttonId)
        })
      } else {
        if (currentScriptUid === _scriptUid) {
          console.debug('🐹 currentScriptUid === _scriptUid', currentScriptUid === _scriptUid)
          this.$store.dispatch('pass/BACK_TO_VIA_INDEX', {
            idx: logIndex,
            _uid: _scriptUid,
          })
          this.$nextTick(() => {
            this.currentMachine.send(event)
            this.$store.dispatch('pass/LOG', {
              stepId: this.currentStepData.step.id,
              buttonId: buttonId,
              valueUnexpected: null,
              isGoal: this.currentStepData.step?.is_goal,
              isUserSort: this.currentStepData.step?.is_user_sort,
            })
            this.scrollToEnd()
          })
        } else {
          console.debug('🐹 currentScriptUid === _scriptUid', currentScriptUid === _scriptUid)
          // console.log('🐹 pass before', JSON.parse(JSON.stringify(this.$store.getters.pass)))
          this.$store.dispatch('pass/BACK_TO_VIA_INDEX', {
            idx: logIndex,
            _uid: _scriptUid,
          })
          this.$store.dispatch('cache/CHANGE_CURRENT_SCRIPT', {
            _uid: _scriptUid,
          })
          this.currentMachine = this.currentScript._xMachine
          this.currentMachine
            .onTransition((state) => {
              this.currentState = state
            })
            .start()

          this.$nextTick(() => {
            this.currentMachine.send(event)
            this.$store.dispatch('pass/LOG', {
              stepId: this.currentStepData.step?.id,
              buttonId: buttonId,
              valueUnexpected: null,
              isGoal: this.currentStepData.step?.is_goal,
              isUserSort: this.currentStepData.step?.is_user_sort,
            })
            this.scrollToEnd()
          })
          // console.log('🐹 pass after', JSON.parse(JSON.stringify(this.$store.getters.pass)))
        }
      }
    },

    initPass() {
      this.$store.dispatch('pass/INIT_PASS')
      this.$nextTick(() => {
        this.$store.dispatch('pass/LOG', {
          stepId: this.currentStepData.step?.id,
          buttonId: '',
          valueUnexpected: null,
          isGoal: this.currentStepData.step?.is_goal,
          isUserSort: this.currentStepData.step?.is_user_sort,
        })
      })

      onScriptStart({
        id: this.currentScript.id,
        name: this.currentScript.name,
        fields: this.currentScript.fields,
      })
    },

    done(withRedirect = false) {
      if (this.isFunctionExecuting) return
      if (this.scriptWaitingToRemove) {
        this.$router.push({ name: this.integrationType + 'Scripts' })
      } else if (this.scriptWaitingToUpdate) {
        this.doneWithRedirect = withRedirect
        const workspace = Object.values(this.$store.getters.pass.passStack).some((pass) =>
          pass.unexpected.some((e) => e.value.trim().length === 0),
        )
        this.isEmptyNoAnswer = workspace
        if (this.isEmptyNoAnswer) {
          return
        }
        this.$store.dispatch('cache/PASS_DONE')

        /**
         * FIXER
         */
        const firstScriptInPass = Object.keys(this.$store.getters.cache.currentWorkspace)[0]
        this.$store.dispatch('cache/CHANGE_CURRENT_SCRIPT', {
          _uid: firstScriptInPass,
          toggleClearWorkspace: true,
        })
        this.currentMachine = this.currentScript._xMachine
        // start
        this.currentMachine
          .onTransition((state) => {
            this.currentState = state
          })
          .start()
        this.$nextTick(() => {
          this.currentMachine.send('start')
        })
        if (withRedirect) {
          this.$router.push({ name: this.integrationType + 'Scripts' })
        } else {
          this.$store.dispatch('cache/UPDATE_SCRIPT', {
            id: this.$route.params.id,
            vm: this,
            updatedCurrentScript: true,
            source: this.integrationType,
          })
        }
      } else {
        this.doneWithRedirect = withRedirect
        onScriptFinish(Object.values(this.$store.getters.pass.passStack))
        const workspace = Object.values(this.$store.getters.pass.passStack).some((pass) =>
          pass.unexpected.some((e) => e.value.trim().length === 0),
        )
        this.isEmptyNoAnswer = workspace
        if (this.isEmptyNoAnswer) {
          return
        }
        this.currentMachine.send('DONE')
        this.$store.dispatch('cache/PASS_DONE')
        /**
         * FIXER
         */
        const firstScriptInPass = Object.keys(this.$store.getters.cache.currentWorkspace)[0]
        this.$store.dispatch('cache/CHANGE_CURRENT_SCRIPT', {
          _uid: firstScriptInPass,
          toggleClearWorkspace: true,
        })
        this.currentMachine = this.currentScript._xMachine
        // start
        this.currentMachine
          .onTransition((state) => {
            this.currentState = state
          })
          .start()
        this.$nextTick(() => {
          this.currentMachine.send('start')
        })
        if (withRedirect) {
          this.$router.push({ name: this.integrationType + 'Scripts' })
        } else {
          this.initPass()
        }
      }
      this.scrollToStart()
    },

    toggleBackToScriptsPage() {
      if (this.isStarted === false) {
        this.$router.push({ name: this.integrationType + 'Scripts' })
        return
      }
      const currentWorkspace = Object.values(this.$store.getters.cache.currentWorkspace)
      const pass = Object.values(this.$store.getters.pass.passStack)[0]
      if (
        (currentWorkspace.length === 1 && pass?.log?.length === 1 && pass.unexpected.length === 0) ||
        this.currentScriptOptions?.autoPassRegistration === false
      ) {
        this.$router.push({ name: this.integrationType + 'Scripts' })
      } else {
        this.done(true)
      }
    },

    /**
     * toggleNoAnswer - method of processing the "Appropriate response is missing" button
     */
    toggleNoAnswer() {
      this.$store.dispatch('pass/LOG', {
        stepId: this.currentStepData.step.id,
        valueUnexpected: '',
      })
    },

    checkNoAnswerFlag(stepId, _uid) {
      if (this.startTime === null) {
        return
      }
      return this.$store.getters.pass?.passStack[_uid]?.unexpected.some((e) => e.id === stepId)
    },

    getNoAnswerValue(stepId, _uid) {
      if (this.startTime === null) {
        return
      }
      return this.$store.getters.pass?.passStack[_uid]?.unexpected.find((e) => e.id === stepId).value
    },

    setNoAnswerValue(value, stepId, _uid) {
      this.$store.dispatch('pass/CHANGE_NO_ANSWER', { id: stepId, value, _uid })
    },

    async toggleIsStarredScript() {
      if (!this.isStarredScript) {
        try {
          await this.$store.dispatch('starredScripts/ADD_STARRED_SCRIPT', {
            script: this.currentScript.id,
          })
          this.isAddedToStarred = true
          setTimeout(() => {
            this.isAddedToStarred = false
          }, 1000)
        } catch (error) {
          this.isSomethingWentWrong = true
          setTimeout(() => {
            this.isSomethingWentWrong = false
          }, 1000)
        }
      } else {
        try {
          this.$store.dispatch('starredScripts/REMOVE_STARRED_SCRIPT', {
            script: this.currentScript.id,
          })
          this.isRemovedFromStarred = true
          setTimeout(() => {
            this.isRemovedFromStarred = false
          }, 1000)
        } catch (error) {
          this.isSomethingWentWrong = true
          setTimeout(() => {
            this.isSomethingWentWrong = false
          }, 1000)
        }
      }
      this.openedScriptMenu = false
    },
    widenTitle() {
      if (this.openedContextButtons === false) {
        return
      }
      this.openedContextButtons = false
      this.$refs.contextButtonsWrapper.focus()
      const closeHandler = () => {
        if (document.activeElement === this.$refs.contextButtonsWrapper) {
          return
        }
        this.openedContextButtons = true
        window.removeEventListener('click', closeHandler)
      }
      window.addEventListener('click', closeHandler)
    },
  },
}
</script>
